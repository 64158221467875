import React from "react";
import CrmTimeline from "./CrmTimeline";
import { useParams } from "react-router";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { formatDateWithoutTime } from "../../utils/formateDate";
import SubmitButton from "../Common/buttons/SubmitButton";


const LeadsHistory = () => {
  const params = useParams();
  const { t } = useTranslation();
  const handleDownload = (link) => {
    // Create an anchor element
    const downloadLink = document.createElement("a");
    downloadLink.href = link; // Replace with the actual file URL
    downloadLink.download = "file1name.extension"; // Replace with the desired file name and extension
    downloadLink.target = "_blank"; // Open in a new tab or window

    // Trigger a click event on the anchor link
    downloadLink.click();

    // Clean up the anchor element
    downloadLink.remove();
  };
  //------------------------------------------------ API -------------------------------------------------
  const timeLines = ApiHook.CallCrmTimeLine(params?.id);

  return (
    <>
      <CrmTimeline />
      <div className="crm-time-line-sec">
        <div className="time-line-form joinings_viewBox">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped bg-success"
              role="progressbar"
              style={{
                width: `${timeLines.data?.leadCompletion?.leadCompletion}%`,
                backgroundColor: `${timeLines.data?.leadCompletion?.color}`,
              }}
              aria-valuenow={timeLines.data?.leadCompletion?.leadCompletion}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <b>{`${timeLines.data?.leadCompletion?.leadCompletion}%`}</b>
            </div>
          </div>
          <ul className="timeline">
            {timeLines?.data?.firstEntry && (
              <li>
                <div className="direction-r">
                  <div className="flag-wrapper">
                    <span className="flag" style={{ height: "auto" }}>
                      <div className="number">
                        <p>{1}</p>
                      </div>
                      <div className="head">
                        {t("history_head", {
                          companyName: timeLines.data?.companyName,
                          addedBy: timeLines.data?.addedBy,
                        })}
                        <br />
                        {timeLines.data?.description}
                      </div>
                    </span>
                    <div className="desc">{(formatDateWithoutTime(timeLines.data?.createdAt))}</div>
                  </div>
                </div>
              </li>
            )}
            {timeLines?.data?.followups?.length !== 0 &&
              timeLines?.data?.followups?.map((item, index) => (
                <li key={index}>
                  <div className={item.direction}>
                    <div className="flag-wrapper">
                      <span className="flag" style={{ height: "auto" }}>
                        <div className="number">
                          <p>{index + 2}</p>
                        </div>
                        <div className="head">
                          {t("follow_up_added_on", {
                            createdAt: item?.createdAt,
                          })}
                          <br />
                          <div className="head">{item.description}</div>
                          <br />
                          {item?.image &&
                              <SubmitButton text="open-download" className="btn btn-primary" click={() =>  handleDownload(item.image)}/>
                          }
                        </div>
                      </span>
                    </div>
                    <div className="desc">{`${t("followup_date")} : ${item.followupDate
                      }`}</div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LeadsHistory;
