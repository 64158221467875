import {MailServices} from "../../services/mailbox/mailbox";

export const Inboxes = async (page, limit) => {
  try {
    return await MailServices.getInboxes(page, limit);
  } catch (error) {
    console.log(error.message);
  }
};

export const SingleMail = async (id, type) => {
  try {
    return await MailServices.getSingleMail(id, type);
  } catch (error) {
    console.log(error.message);
  }
};

export const ReplyMail = async (replyMail) => {
  try {
    return (await MailServices.replyMail(replyMail)).data;
  } catch (error) {
    console.log(error.message);
  }
};

export const AdminInboxes = async (page, limit) => {
  try {
    return await MailServices.getInboxFromAdmin(page, limit);
  } catch (error) {
    console.log(error.message);
  }
};

export const SendInternalMail = async (mailContent) => {
  try {
    return (await MailServices.sendInternalMail(mailContent)).data;
  } catch (error) {
    console.log(error.message);
  }
};

export const DeleteMail = async (mailId) => {
  try {
    return (await MailServices.deleteMail(mailId)).data;
  } catch (error) {
    console.log(error.message);
  }
};

export const SentMail = async (page, limit) => {
  try {
    return await MailServices.sentMail(page, limit);
  } catch (error) {
    console.log(error.message);
  }
};

export const replicaInbox = async (page, limit) => {
  try {
    return await MailServices.contacts(page, limit);
  } catch (error) {
    console.log(error.message);
  }
};
