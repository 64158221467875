import {toolsServices} from "../../services/Tools/tools";

export const getFaqs = async () => {
  try {
    return toolsServices.getFaqs();
  } catch (error) {
    return error.message;
  }
};

export const getNews = async () => {
  try {
    return toolsServices.getNews();
  } catch (error) {
    return error.message;
  }
};

export const getNewsById = async (newsId) => {
  try {
    return toolsServices.getNewsById(newsId);
  } catch (error) {
    return error.message;
  }
};

export const getLeads = async (page, itemsPerPage) => {
  try {
    return toolsServices.getLeads(page, itemsPerPage);
  } catch (error) {
    return error.message;
  }
};

export const searchLeads = async (name, page, itemsPerPage) => {
  try {
    return toolsServices.searchLead(name, page, itemsPerPage);
  } catch (error) {
    return error.message;
  }
};

export const updateLead = async (data) => {
  try {
    const id = data.leadId;
    delete data.leadId;

    return toolsServices.updateLead(data, id);
  } catch (error) {
    return error.message;
  }
};

export const getReplicaBanner = async () => {
  try {
    return toolsServices.getReplicaBanner();
  } catch (error) {
    return error.message;
  }
};

export const uploadReplicaBanner = async (file) => {
  try {
    return toolsServices.uploadReplicaBanner(file);
  } catch (error) {
    return error.response.data;
  }
};

export const deleteReplicaBanner = async (bannerId) => {
  try {
    return toolsServices.deleteReplicaBanner(bannerId);
  } catch (error) {
    return error.response.data;
  }
};

export const getDownloadMaterials = async () => {
  try {
    return toolsServices.getDownloadMaterials();
  } catch (error) {
    return error.response.data;
  }
};
