import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileTimePicker } from "@mui/x-date-pickers";
// import dayjs from 'dayjs';

const TimePickerComponent = ({
    className,
    time,
    handleTimeChange,
    isClockOpen,
    openClock,
    closeClock,
    disabled,
    disablePast
}) => {
   
    const handleCloseTimePicker = () => {
        closeClock();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} id="parent">
            <DemoContainer id="check" components={["MobileTimePicker"]}>
                <DemoItem label="">
                    <div id="parent">
                        <MobileTimePicker
                            format="HH:mm"
                            ampm={false}
                            open={isClockOpen}
                            onOpen={openClock}
                            onClose={handleCloseTimePicker}
                            className={className}
                            value={time}
                            onChange={handleTimeChange}
                            onAccept={closeClock}
                            disabled={disabled}
                            disablePast={disablePast}
                        />
                    </div>
                </DemoItem>
            </DemoContainer>
        </LocalizationProvider>
    )
};

export default TimePickerComponent;