import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ApiHook } from '../../hooks/apiHook';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

const InviteGuest = () => {
  const location = useLocation();
  const partyId = location.state?.selectedParty?.value; // Safeguard with optional chaining
  const [selectedGuestIds, setSelectedGuestIds] = useState([]);
  const queryClient = useQueryClient();
  const details = ApiHook.CallPartyGuestsById(partyId);
  const invite = ApiHook.CallInviteGuest();

  const handleCheckboxChange = (guestId) => {
    setSelectedGuestIds((prevSelectedGuestIds) => {
      if (prevSelectedGuestIds.includes(guestId)) {
        return prevSelectedGuestIds.filter(id => id !== guestId);
      } else {
        return [...prevSelectedGuestIds, guestId];
      }
    });
  };

  const handleSendInvite = () => {
    const data = {
      partyId,
      guestIdArr: selectedGuestIds
    };
    console.log(data);
    invite.mutate(data, {
      onSuccess: (res) => {
        if (res.status) {
          toast.success(res?.data);
          queryClient.invalidateQueries({queryKey:['party-guest-by-id']})
        } else {
          toast.error(res?.data?.description);
        }
      }
    });
  };

  return (
    <div className="invite-guest">
      <div className="page_head_top">Invite Guest</div>

      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          <div className="table-responsive min-height-table-2">
            <div className="p-3">
              <table className="table table-manage-invite-party-portal invite-party-portal-processed-Orders">
                <thead>
                  <tr className="th">
                    <th>#</th>
                    <th>Select</th>
                    <th>Guest Name</th>
                    <th>Guest Email</th>
                    <th>Guest Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {details?.data?.guests?.length > 0 ? (
                    details.data.guests.map((guest, index) => (
                      <tr key={guest.id}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedGuestIds.includes(guest.id)}
                            onChange={() => handleCheckboxChange(guest.id)}
                          />
                        </td>
                        <td>{guest.name}</td>
                        <td>{guest.email}</td>
                        <td>{guest.phone}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No data found...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex gap-2 ms-2">
            <button className="btn mt-1 rounded-1 setup-Party text-white" onClick={handleSendInvite}>Send Invite</button>
            <Link className="btn mt-1 rounded-1 setup-Party text-white" to='/guest-management'>Add Another Guest</Link>
            <Link className="btn mt-1 rounded-1 setup-Party text-white btn-back-option" to="/my-party-portal" state={{selectedParty: location.state?.selectedParty}}>Back to Party Portal</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteGuest;
