import {SupportServices} from "../../services/support/support";

export const getTickets = async (page,itemsPerPage,category,priority,ticketId,status) => {
    try {
        return await SupportServices.getTickets(page, itemsPerPage, category, priority, ticketId, status);
    } catch (error) {
        console.log(error);
        return error.message
    }
}

export const getTicketPartials = async () => {
    try {
        return await SupportServices.getTicketPartials();
    } catch (error) {
        console.log(error);
        return error.message
    }
}

export const getTrackId = async () => {
    try {
        return await SupportServices.getTrackId();
    } catch (error) {
        console.log(error);
        return error.message
    }
}

export const createTicket = async (data) => {
    try {
        return await SupportServices.createTicket(data);
    } catch (error) {
        console.log(error);
        return error.message
    }
}

export const getTicketDetails = async (trackId) => {
    try {
        return await SupportServices.getTicketDetails(trackId);
    } catch (error) {
        console.log(error);
        return error.message
    }
}

export const getTicketReplies = async (trackId) => {
    try {
        return await SupportServices.getTicketReplies(trackId);
    } catch (error) {
        console.log(error);
        return error.message
    }
}

export const getTicketFaqs = async () => {
    try {
        return await SupportServices.getTicketFaqs();
    } catch (error) {
        console.log(error);
        return error.message
    }
}

export const ticketReply = async (data) => {
    try {
        return await SupportServices.ticketReply(data);
    } catch (error) {
        console.log(error);
        return error.message
    }
}

export const ticketTimeline = async (trackId) => {
    try {
        return await SupportServices.ticketTimeline(trackId);
    } catch (error) {
        console.log(error);
        return error.message
    }
}