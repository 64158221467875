import {EwalletService} from "../../services/ewallet/ewallet";

export const Tiles = async () => {
    try {
        return await EwalletService.callTiles()
    } catch (error) {
        return error.message
    }
}

export const Statement = async (page, itemsPerPage, sortBy, direction) => {
    try {
        return await EwalletService.callStatement(page, itemsPerPage, sortBy, direction)
    } catch (error) {
        return error.message
    }
}

export const TransferHistory = async (page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction) => {
    try {
        return await EwalletService.callTransferHistory(page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction)
    } catch (error) {
        return error.message
    }
}

export const PurchaseHistory = async (page, itemsPerPage, sortBy, direction) => {
    try {
        return await EwalletService.callPurchaseHistory(page, itemsPerPage, sortBy, direction)
    } catch (error) {
        return error.message
    }
}

export const MyEarnings = async (page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction) => {
    try {
        return await EwalletService.callMyEarnings(page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction)
    } catch (error) {
        return error.message
    }
}

export const FundTransfer = async (data) => {
    try {
        return await EwalletService.callFundTransfer(data)
    } catch (error) {
        return error.message
    }
}

export const EwalletBalance = async () => {
    try {
        return await EwalletService.callEwalletBalance()
    } catch (error) {
        console.log(error.message);
    }
}