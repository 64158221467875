import DashboardService from "../../services/dashboard/Dashboard"


export const AppLayout = async () => {
    try {
        return await DashboardService.appLayout()
    } catch (error) {
        return error.message
    }
}

export const DashboardUserProfile = async () => {
    try {
        return await DashboardService.dashboardProfile()
    } catch (error) {
        return error.message
    }
}

export const DashboardTiles = async () => {
    try {
        return await DashboardService.dashboardTiles()
    } catch (error) {
        return error.message
    }
}

export const GetGraph = async (params) => {
    try {
        return await DashboardService.getGraph(params)
    } catch (error) {
        return error.message
    }
}

export const NotificationData = async () => {
    try {
        return await DashboardService.notificationCall()
    } catch (error) {
        console.log(error.message);
    }
}

export const ReadNotification = async (id) => {
    try {
        return await DashboardService.readnotification(id)
    } catch (error) {
        console.log(error.message)
    }
}

export const ReadAllNotification = async () => {
    try {
        return await DashboardService.ReadAllNotification()
    } catch (error) {
        console.log(error.message)
    }
}

export const DashboardDetails = async () => {
    try {
        return await DashboardService.dashboardDetails()
    } catch (error) {
        return error.message
    }
}

export const PackageOverview = async () => {
    try {
        return await DashboardService.packageOverview()
    } catch (error) {
        return error.message
    }
}

export const RankOverview = async () => {
    try {
        return await DashboardService.rankOverview()
    } catch (error) {
        return error.message
    }
}

export const TopRecruiters = async () => {
    try {
        return await DashboardService.topRecruiters()
    } catch (error) {
        return error.message
    }
}

export const DashboardExpenses = async () => {
    try {
        return await DashboardService.dashboardExpenses()
    } catch (error) {
        return error.message
    }
}

export const EndTutorial = async () => {
    try {
        return (await DashboardService.endTutorial())?.data
    } catch (error) {
        return error.message
    }
}