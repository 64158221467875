import {OrderService} from "../../services/order/order";
import {PaymentService} from "../../services/payment/payment";

export const GetOrder = async(orderType, orderId) => {
    try {
        return await OrderService.callGetOrder(orderType, orderId);
    } catch (error) {
        console.log(error.message);
    }
}

export const GetPaymentStatus = async(paymentMethod, invoiceNo) => {
    try {
        return await PaymentService.callGetPaymentStatus(paymentMethod, invoiceNo);
    } catch (error) {
        console.log(error.message);
    }
}