import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PartyTableMain from "../../components/Party/PartyTableMain";
import { ApiHook } from "../../hooks/apiHook";

const GuestManagement = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const guestDetails = ApiHook.CallGetPartyGuests(itemsPerPage, currentPage);
    return (
        <>
            <div className="page_head_top">{t("guestManagement")}</div>
            <PartyTableMain
                type="guests"
                filterButtonPath="/add-guest"
                filterButtonText="addGuest"
                tableData={guestDetails?.data}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
};

export default GuestManagement;