import API from "../../api/api";

export const upgradeService = {
  getUpgradeProducts: async () => {
    return API.get(`get-upgrade-products`);
  },
  upgradeSubscription: async (data) => {
    return API.post(`upgrade`, JSON.stringify(data));
  },
};
