import {EpinService} from "../../services/epin/epin"

export const EpinTiles = async () => {
    try {
        return await EpinService.callEpinTiles()
    } catch (error) {
        return error.message
    }
}

export const EpinList = async (page, perPage, epin, amount, status, sortBy, direction) => {
    try {
        return await EpinService.callEpinList(page, perPage, epin, amount, status, sortBy, direction)
    } catch (error) {
        return error.message
    }
}

export const EpinPendingRequest = async (page, perPage, sortBy, direction) => {
    try {
        return await EpinService.callEpinPendingRequest(page, perPage, sortBy, direction)
    } catch (error) {
        return error.message
    }
}

export const EpinTransferHistory = async (page, perPage, sortBy, direction) => {
    try {
        return await EpinService.callEpinTransferHistory(page, perPage, sortBy, direction)
    } catch (error) {
        return error.message
    }
}

export const EpinTransfer = async (data) => {
    try {
        return await EpinService.callEpinTransfer(data)
    } catch (error) {
        return error.message
    }
}

export const EpinPurchase = async (data) => {
    try {
        return await EpinService.callEpinPurchase(data)
    } catch (error) {
        return error.message
    }
}

export const EpinRequest = async (data) => {
    try {
        return await EpinService.callEpinRequest(data)
    } catch (error) {
        return error.message
    }
}

export const EpinRefund = async (data) => {
    try {
        return await EpinService.callEpinRefund(data)
    } catch (error) {
        return error.message
    }
}

export const PurchasedEpinList = async () => {
    try {
        return await EpinService.callPurchasedEpinList()
    } catch (error) {
        return error.message
    }
}

export const EpinPartials = async () => {
    try {
        return await EpinService.callEpinPartials()
    } catch (error) {
        return error.message
    }
}