import React, { useState } from "react";
import LeadsTable from "../../components/Crm/LeadsTable";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";

const Leads = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [fetchable, setIsFetchable] = useState(true);
  const leadsData = ApiHook.CallSearchLeads(currentPage, itemsPerPage, searchKey, fetchable);
  const countries = ApiHook.CallGetCountries();
  return (
    <>
      <div className="page_head_top">
        {t('leads')}
        <div className="right_btn_mob_toggle">
          <i className="fa fa-bars"></i>
        </div>
      </div>
      <LeadsTable
        tableData={leadsData?.data?.data?.leads}
        currentPage={leadsData?.data?.data?.currentPage}
        totalPages={leadsData?.data?.data?.totalPages}
        setCurrentPage={setCurrentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        setSearchKey={setSearchKey}
        replicaUrl={leadsData?.data?.replicaUrl}
        setIsFetchable={setIsFetchable}
        fetchable={fetchable}
        countries={countries.data}
      />
    </>
  );
};

export default Leads;
