import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        //if (response.status === 200)
        return response?.data?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const callApiRequest = async (endpoint, method, data) => {
    try {
        const response = await API.request({url: endpoint, method, data, headers: {'Content-Type': 'multipart/form-data'}});
        //if (response.status === 200)
        return response?.data?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const SupportServices = {
    getTickets: async (page, itemsPerPage, category, priority, ticketId, status) => {
        return await callApi(`tickets?trackId=${ticketId}&category=${category}&priority=${priority}&status=${status}&page=${page}&perPage=${itemsPerPage}`);
    },
    getTicketPartials: async () => {
        return await callApi(`ticket-partials`);
    },
    getTrackId: async () => {
        return await callApi('ticket-trackId');
    },
    createTicket: async (data) => {
        const formData = new FormData();

        [...data.attachment].forEach((file, i) => {
            formData.append(`file`, file);
        });
        formData.append('categoryId', data.category);
        formData.append('subject', data.subject);
        formData.append('trackId', data.ticketId);
        formData.append('message', data.message);

        try {
            const response = await callApiRequest(`ticket`, 'post', formData);
            return response.data;
        } catch (error) {
            return error?.response?.data;
        }
    },
    getTicketDetails: async (trackId) => {
        return await callApi(`ticket-details/${trackId}`);
    },
    getTicketReplies: async (trackId) => {
        return await callApi(`ticket-replies/${trackId}`);
    },
    ticketReply: async (data) => {
        const formData = new FormData();

        [...data.files].forEach((file, i) => {
            formData.append(`file`, file);
        });
        formData.append('msg', data.message);

        try {
            const response = await callApiRequest(`ticket-chat/${data.trackId}`, 'put', formData);
            return response.data;
        } catch (error) {
            return error?.response?.data;
        }
    },
    getTicketFaqs: async () => {
        return await callApi("ticket-faq");
    },
    ticketTimeline: async (trackId) => {
        return await callApi(`ticket-timeline/${trackId}`);
    }
}