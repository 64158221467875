import {ShoppingService} from "../../services/shopping/shopping"

export const getRepurchaseItems = async () => {
    try {
        return await ShoppingService.callRepurchaseItems()
    } catch (error) {
        return error.message
    }
}

export const addToCart = async (data) => {
    try {
        return await ShoppingService.callAddToCart(data)
    } catch (error) {
        return error.message
    }
}

export const getCartItems = async () => {
    try {
        return await ShoppingService.callCartItems()
    } catch (error) {
        return error.message
    }
}

export const decrementCartItem = async (data) => {
    try {
        return await ShoppingService.callDecrementCartItem(data)
    } catch (error) {
        return error.message
    }
}

export const removeCartItem = async (data) => {
    try {
        return await ShoppingService.callRemoveCartItem(data)
    } catch (error) {
        return error.message
    }
}

export const addAddress = async (data) => {
    try {
        return await ShoppingService.callAddAddress(data)
    } catch (error) {
        return error.message
    }
}

export const getAddress = async () => {
    try {
        return await ShoppingService.callAddress()
    } catch (error) {
        return error.message
    }
}

export const callPaymentMethods = async (action) => {
    try {
        return await ShoppingService.callPaymentMethods(action)
    } catch (error) {
        return error.message
    }
}

export const removeAddress = async (data) => {
    try {
        return await ShoppingService.callRemoveAddress(data)
    } catch (error) {
        return error.message
    }
}

export const ProductDetails = async (id) => {
    try {
        return await ShoppingService.callProductDetails(id)
    } catch (error) {
        console.log(error.message);
    }
}

export const DefaultAddressChange = async (id) => {
    try {
        return await ShoppingService.callDefaultAddressChange(id)
    } catch (error) {
        console.log(error.message);
    }
}

export const PlaceRepurchaseOrder = async (data) => {
    try {
        return await ShoppingService.callPlaceRepurchaseOrder(data)
    } catch (error) {
        console.log(error.message);
    }
}

export const RepurchaseReport = async (page,limit, sortBy, direction) => {
    try {
        return await ShoppingService.callRepurchaseReport(page, limit, sortBy, direction)
    } catch (error) {
        console.log(error.message);
    }
}

export const RepurchaseInvoice = async(invoiceNo) => {
    try {
        return await ShoppingService.callPurchaseInvoice(invoiceNo)
    } catch (error) {
        console.log(error.message);       
    }
}

export const CreateReport = async(data) => {
    try {
        return await ShoppingService.callCreateReport(data.reportType, data.fileType)
    } catch (error) {
        console.log(error.message);
    }
}