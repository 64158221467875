import { ApiHook } from "../../hooks/apiHook";
//import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const Payment = () => {
  //const { t } = useTranslation();
  const param = useParams();
  ApiHook.CallGetPaymentStatus(param.paymentMethod, param.invoiceNo);
};

export default Payment;
