import API from "../../api/api";


const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const PaymentService = {
    callGetPaymentStatus: async (paymentMethod, invoiceNo) => {
        return await callApi(`getPaymentStatus?paymentMethod=${paymentMethod}&invoiceNo=${invoiceNo}`);
    },
}