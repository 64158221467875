import {DEFAULT_KEY} from "../../config/config";
import backToOffice from "../../services/auth/backToOffice";
import {useNavigate, useParams} from "react-router";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const BackToOffice = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const string = params?.string;

  useEffect(() => {
    backToOffice.accessToken(string, DEFAULT_KEY).then((res) => {
      localStorage.setItem("access-token", res.data?.accessToken);
      localStorage.setItem("api-key", res.data?.apiKey);
      localStorage.setItem("user", JSON.stringify(res.data?.user));
      localStorage.setItem(
          "defaultCurrency",
          JSON.stringify(res.data?.defaultCurrency)
      );
      localStorage.setItem(
          "defaultLanguage",
          JSON.stringify(res.data?.defaultLanguage)
      );
      window.location.href = "/dashboard";
    }).catch((err) => {
      toast.error(t("invalidUsername-UsernameNotFound"));
      navigate("/login");
    });
  }, [navigate, string]);
};

export default BackToOffice;
