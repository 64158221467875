import {ReplicaService} from "../../services/replica/replica"

export const getApiKey = async (adminUsername) => {
    try {
        return await ReplicaService.getApiKey(adminUsername)
    } catch (error) {
        return error.message
    }
}

export const ReplicaHome = async () => {
    try {
        return await ReplicaService.getReplicaHome()
    } catch (error) {
        return error.message
    }
}

export const ReplicaRegisterFields = async () => {
    try {
        return await ReplicaService.getReplicaRegister()
    } catch (error) {
        return error.message
    }
}

export const replicaFieldCheck = async (field, value) => {
    try {
        return await ReplicaService.getFieldCheck(field, value)
    } catch (error) {
        return error.message
    }
}

export const ReplicaBankUploadReceipt = async (data, username, referralId, type) => {
    try {
        return await ReplicaService.callBankUpload(data, username, referralId, type)
    } catch (error) {
        return error.message
    }
}

export const ReplicaBankRecieptDelete = async (data) => {
    try {
        return await ReplicaService.ReplicaBankRecieptDelete(data)
    } catch (error) {
        return error.message
    }
}

export const ReplicaRegisterPost = async (data) => {
    try {
        return await ReplicaService.CallReplicaRegister(data)
    } catch (error) {
        return error.message
    }
}

export const ReplicaContactUpload = async (data) => {
    try {
        return await ReplicaService.replicaContactUpload(data)
    } catch (error) {
        return error.message
    }
}

export const ReplicaCreateStripeIntent = async (data) => {
    try {
        return await ReplicaService.replicaCreateStripePaymentIntent(data);
    } catch (error) {
        return error.message
    }
}

export const ReplicaUpdateStripeIntent = async (data) => {
    try {
        return await ReplicaService.replicaUpdateStripePaymentIntent(data);
    } catch (error) {
        return error.message
    }
}

export const ReplicaCancelStripeIntent = async (data) => {
    try {
        return await ReplicaService.replicaCancelStripePaymentIntent(data);
    } catch (error) {
        return error.message
    }
}