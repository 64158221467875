import React from 'react';
import { Link } from 'react-router-dom';
import CurrencyConverter from '../../../Currency/CurrencyConverter';

const ProcessedOrders = (props) => {
    const totalQuantity = props?.orders?.reduce((sum, order) => sum + order.quantity, 0);
    const totalAmount = props?.orders?.reduce((sum, order) => sum + order.totalAmount, 0);

    return (
        <div className="table-responsive min-hieght-table-2 mb-4">
            <div className="p-3">
                <div className="py-0">
                    <h5 className="m-0">Processed Orders</h5>
                </div>

                <table className="table table-manage-invite-party-portal mb-4 border-top1">
                    <thead>
                        <tr className="th">
                            <th>No</th>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props?.orders?.length > 0 ? (
                            props?.orders?.map((order, index) => (
                                <tr key={order.id}>
                                    <td>{index + 1}</td>
                                    <td>{order.productName}</td>
                                    <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(order?.price, props.conversionFactor)}</td>
                                    <td>{order.quantity}</td>
                                    <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(order?.totalAmount, props.conversionFactor)}</td>
                                </tr>
                            ))) : (<tr><td colSpan="5">No data found...</td></tr>)
                        }
                    </tbody>
                </table>
                {props?.orders?.length > 0 && (
                    <>
                        <table className="dashed_table_ttl_amnt mb-4">
                            <tbody>
                                <tr>
                                    <td>Quantity : </td>
                                    <td>{totalQuantity}</td>
                                </tr>
                                <tr>
                                    <td>Total :</td>
                                    <td><strong>{props.currency?.symbolLeft}{" "}{CurrencyConverter(totalAmount, props.conversionFactor)}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )}
                <Link to='/my-party-portal' className="btn mt-1 py-2 rounded-1 setup-Party text-white btn-back-option" state={{'selectedParty': props?.selectedParty}}>Back to Party Portal</Link>
            </div>
        </div>
    );
};

export default ProcessedOrders;
