import React from "react";
import { useTranslation } from "react-i18next";
import PartyHostGuestForm from "../../components/Party/PartyHostGuestForm";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useLocation } from "react-router";

const AddHost = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const editData = location.state?.rowData;

    const addHostMutation = ApiHook.CallAddHost();
    const updateHostMutation = ApiHook.CallUpdateHost();

    const addHost = (data, clearForm) => {
        addHostMutation.mutateAsync(data, {
            onSuccess: (res) => {
                if (res.status) {
                    toast.success(t(res.data));
                    clearForm();
                } else {
                    toast.error(t(res.data));
                }
            }
        })
    }
    const updateHost = (data) => {
        updateHostMutation.mutateAsync(data, {
            onSuccess: (res) => {
                if (res.status) {
                    toast.success(t(res.data));
                } else {
                    toast.error(t(res.data));
                }
            }
        })
    }
    return (
        <>
            <div className="page_head_top">{t("addHost")}</div>
            {/* content here */}
            <PartyHostGuestForm
                type={editData ? "updateHost" : "addHost"}
                backBtnPath="/host-management"
                submitFunction={editData ? updateHost : addHost}
                editData={editData}
            />
        </>
    );
};

export default AddHost;