import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ApiHook } from '../../../hooks/apiHook';
import { useSelector } from 'react-redux';
import CurrencyConverter from '../../../Currency/CurrencyConverter';

const OrderProduct = () => {
    const location = useLocation();
    const partyId = location.state?.selectedParty?.partyId ?? JSON.parse(localStorage.getItem('partyId'));
    const selectedParty = location.state?.selectedParty;
    const currency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    // ---------------------------------- API's -------------------------------------------- //
    const guestOrders = ApiHook.CallGuestOrders(partyId, 10, 1);




    return (
        <>
            <div className="page_head_top">Order Party Product</div>
            <GuestTable
                guests={guestOrders?.data?.guests}
                partyId={partyId}
                selectedParty={selectedParty}
                currency={currency}
                conversionFactor={conversionFactor}
            />

        </>
    );
};

const GuestRow = ({ index, guest, partyId, selectedParty, currency, conversionFactor }) => (
    <tr key={guest.id}>
        <td>{index + 1}</td>
        <td>{guest?.name}</td>
        <td>{guest?.quantity}</td>
        <td>{currency?.symbolLeft}{" "}{CurrencyConverter(guest?.total, conversionFactor)}</td>
        <td>{guest?.address}</td>
        <td>
            <Link to={'/party-products'} className="btn mt-1 rounded-2 setup-Party text-white" state={{ 'selectedParty': selectedParty, 'guestId': guest.id }}>
                Order Product
            </Link>
        </td>
    </tr>
);

const GuestTable = ({ guests, partyId, selectedParty, currency, conversionFactor }) => (
    <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
            <div className="table-responsive min-height-table-2">
                <div className="p-3">
                    <table className="table table-manage-invite-party-portal invite-party-portal-processed-Orders">
                        <thead>
                            <tr className="th">
                                <th>#</th>
                                <th>Guest Name</th>
                                <th>Orders</th>
                                <th>Amount</th>
                                <th>Address</th>
                                <th>Order</th>
                            </tr>
                        </thead>
                        <tbody>
                            {guests?.map((guest, index) => (
                                <GuestRow
                                    index={index}
                                    guest={guest}
                                    partyId={partyId}
                                    selectedParty={selectedParty}
                                    currency={currency}
                                    conversionFactor={conversionFactor} />
                            ))}
                        </tbody>
                    </table>
                    <div className="mb-2 d-flex justify-content-end w-100">
                        <Link
                            to="/my-party-portal"
                            className="btn mt-1 py-2 rounded-1 setup-Party text-white btn-back-option"
                            state={{ 'selectedParty': selectedParty }}
                        >
                            Back to Party Portal
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default OrderProduct;
