import {PayoutService} from "../../services/payout/payout";

export const TilesAndDetails = async (page, perPage, type, sortBy, direction) => {
    try {
        return await PayoutService.callDetails(page, perPage, type, sortBy, direction)
    } catch (error) {
        return error.message
    }
}

export const PayoutRequestDetails = async () => {
    try {
        return await PayoutService.callPayoutRequestDetails()
    } catch (error) {
        return error.message
    }
}

export const PayoutRequestApi = async (data) => {
    try {
        return await PayoutService.callPayoutRequest(data)
    } catch (error) {
        return error.message
    }
}

export const PayoutTiles = async() => {
    try {
        return await PayoutService.callPayoutTiles()
    } catch (error) {
        return error.message
    }
}