import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CurrencyConverter from '../../../Currency/CurrencyConverter';

const ViewOrdersModal = ({ show, handleClose, details }) => {

  const currency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );

  const total = (details?.quantity * details?.totalAmount)
  return (
    <Modal show={show} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>View Orders</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered className="table-manage-invite-party-portal">
          <thead>
            <tr>
              <th>#</th>
              <th>Guest</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{details?.id}</td>
              <td>{details?.guest}</td>
              <td>{details?.quantity}</td>
              <td>{currency?.symbolLeft}{" "}{CurrencyConverter(details?.totalAmount, conversionFactor)}</td>
              <td>{currency?.symbolLeft}{" "}{CurrencyConverter(total, conversionFactor)}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewOrdersModal;
