import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DatePickerComponent from "../../Common/DatePickerComponent";
import TimePickerComponent from "../../Common/TimePickerComponent";
import dayjs from 'dayjs';

const SelectPartyTime = (props) => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(props.formValues.fromDate ? dayjs(props.formValues.fromDate) : null);
    const [endDate, setEndDate] = useState(props.formValues.toDate ? dayjs(props.formValues.toDate) : null);
    const [startTime, setStartTime] = useState(props.formValues.fromTime ? dayjs(props.formValues.fromTime, 'HH:mm') : null);
    const [endTime, setEndTime] = useState(props.formValues.toTime ? dayjs(props.formValues.toTime, 'HH:mm') : null);


    const [isCalenderOpen, setIsCalenderOpen] = useState({
        startDate: false,
        endDate: false
    });
    const [isClockOpen, setIsClockOpen] = useState({
        startTime: false,
        endTime: false
    })
    const handleDateChange = (newDate, assign) => {
        if (assign === "fromDate") {
            setStartDate(newDate);
        } else {
            setEndDate(newDate);
        }

        if (newDate) {
            const formattedDate = newDate.format("YYYY-MM-DD");
            props.setValue(assign, formattedDate);
        } else {
            props.setValue(assign, null);
        }

        props.clearErrors(assign);
    };
    const handleTimeChange = (newTime, assign) => {
        if (assign === "fromTime") {
            setStartTime(newTime);
        } else {
            setEndTime(newTime);
        }

        if (newTime) {
            const formattedTime = newTime.format('HH:mm');
            props.setValue(assign, formattedTime);
        } else {
            props.setValue(assign, null);
        }

        props.clearErrors(assign);
    }

    return (
        <>
            <div className="row row_top justify-content-center form-business">
                <div className="regsiter_step_1_view_left_sec_head">{t('selectPartyTime')}</div>
                <div className="regsiter_second_step_bx">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="regsiter_form_box">
                                <label>{t('startDate')} <span>*</span></label>
                                <DatePickerComponent
                                    className={"date-picker"}
                                    date={startDate}
                                    handleDateChange={(date) => handleDateChange(date, "fromDate")}
                                    isCalenderOpen={isCalenderOpen.startDate}
                                    openCalender={() => setIsCalenderOpen({ ...isCalenderOpen, startDate: true })}
                                    closeCalender={() => setIsCalenderOpen({ ...isCalenderOpen, startDate: false })}
                                    past={true}
                                />
                                <span className="error-message-validator">
                                    {t(props.errors?.fromDate?.message)}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="regsiter_form_box">
                                <label>{t('startTime')}<span>*</span></label>
                                <TimePickerComponent
                                    className={"date-picker"}
                                    time={startTime}
                                    handleTimeChange={(time) => handleTimeChange(time, "fromTime")}
                                    isClockOpen={isClockOpen.startTime}
                                    openClock={() => setIsClockOpen({ ...isClockOpen, startTime: true })}
                                    closeClock={() => setIsClockOpen({ ...isClockOpen, startTime: false })}
                                />
                                <span className="error-message-validator">
                                    {t(props.errors?.fromTime?.message)}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="regsiter_form_box">
                                <label>{t('endDate')}<span>*</span></label>
                                <DatePickerComponent
                                    className={"date-picker"}
                                    date={endDate}
                                    handleDateChange={(date) => handleDateChange(date, "toDate")}
                                    isCalenderOpen={isCalenderOpen.endDate}
                                    openCalender={() => setIsCalenderOpen({ ...isCalenderOpen, endDate: true })}
                                    closeCalender={() => setIsCalenderOpen({ ...isCalenderOpen, endDate: false })}
                                    past={true}
                                />
                                <span className="error-message-validator">
                                    {t(props.errors?.toDate?.message)}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="regsiter_form_box">
                                <label>{t('endTime')} <span>*</span></label>
                                <TimePickerComponent
                                    className={"date-picker"}
                                    time={endTime}
                                    handleTimeChange={(time) => handleTimeChange(time, "toTime")}
                                    isClockOpen={isClockOpen.endTime}
                                    openClock={() => setIsClockOpen({ ...isClockOpen, endTime: true })}
                                    closeClock={() => setIsClockOpen({ ...isClockOpen, endTime: false })}
                                />
                                <span className="error-message-validator">
                                    {t(props.errors?.toTime?.message)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectPartyTime;