import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const PartyTableContent = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <table className="striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t('firstName')}</th>
                        <th>{t('lastName')}</th>
                        <th>{t('email')}</th>
                        <th>{t('mobile')}</th>
                        <th>{t('address')}</th>
                        <th>{t('action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props?.tableContent?.map((row, index) => (
                        <tr key={index}>
                            <td>{((props.currentPage - 1) * 10) + index + 1}</td>
                            <td>{row.name}</td>
                            <td>{row.secondName}</td>
                            <td>{row.email}</td>
                            <td>{row.phone}</td>
                            <td>{row.address}</td>
                            <td>
                                <NavLink
                                    to={props.path}
                                    state={{ rowData:row }}
                                    >
                                    <button data-bs-toggle="modal" data-bs-target="#leadview" type="button" className="btn btn-primary">{t('edit')}</button>
                                </NavLink>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
};

export default PartyTableContent;